@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Fonts Family */
@font-face {
  font-family: 'Bambino-Regular';
  src: url('./../public/fonts/Bambino.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Global Styles */
@layer base {
  .font-bambino {
    font-family: 'Bambino-Regular', sans-serif;
  }

  .font-jost {
    font-family: "Jost", sans-serif;
  }

  .font-poppins {
    font-family: "Poppins", sans-serif;
  }

  .text-shadow-default {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

html, body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: 'poppins', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Gradient border button styles */
.gradient-border-btn {
  border: 8px solid transparent;
  border-radius: 9999px;
  background-image: linear-gradient(white, white), linear-gradient(to right, #2C93D4, #19B6B5); /* Dual background gradients */
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.gradient-border-btn:hover {
  background-image: linear-gradient(inherit, inherit), linear-gradient(to right, #1E3A8A, #3B82F6); /* Change gradient on hover */
}

/* Dark mode styles for gradient border button */
.dark .gradient-border-btn {
  background-image: linear-gradient(#1f2937, #1f2937), linear-gradient(to right, #2177A9, #158F8E); /* Adjust dark mode colors */
}

.dark .gradient-border-btn:hover {
  background-image: linear-gradient(#1f2937, #1f2937), linear-gradient(to right, #0E3D68, #2BA5D6); /* Adjust dark mode hover colors */
}

/* Animation styles */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Styles for images used in StepsSection */
.img-img1 {
  margin-top: 90px; 
  margin-right: 2px; 
}

.img-img2 {
  margin-top: -5px; 
  margin-left: -50px; 
}

.img-img3 {
  margin-top: 160px;
  margin-left: -15px;
}

.img-img4 {
  margin-top: 50px;
  margin-left: 16px;
}

@media (max-width: 1279px){
  .hide-on-small {
    display: none;
  }
}

@media (min-width: 768px) {
  .card1 { grid-area: 1 / 1 / 2 / 2; }
  .img-img1 { grid-area: 1 / 2 / 2 / 3; }
  .card2 { grid-area: 1 / 3 / 2 / 4; }
  .img-img3 { grid-area: 2 / 1 / 3 / 2; }
  .card3 { grid-area: 2 / 2 / 3 / 3; }
  .img-img2 { grid-area: 2 / 3 / 3 / 4; }
  .card4 { grid-area: 3 / 1 / 4 / 2; }
  .img-img4 { grid-area: 3 / 2 / 4 / 3; }
  .card5 { grid-area: 3 / 3 / 4 / 4; }
}


/* Admin */
.loader {
  border: 4px solid transparent;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hide native number input buttons */
[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
  @apply appearance-none;
  margin: 0;
}

[type="number"] {
  @apply appearance-none;
}

/*custom phone screen*/
/*
@media (max-wdith: 600px) {
  .phone-screen-width {
    width: 10rem;
  }
  .phone-screen-font {
    font-size: 10px;
  }
  .phone-screen {
    padding: 0px;
    line-height: 1.1;
  }
} */
